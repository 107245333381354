<template>
  <div class="page-showing-setup">
    <SdDiscardChangesDialog
      v-if="uiFlags.leavingToRouteNext !== null"
      message="Are you sure you want to leave and discard changes?"
      @close="uiFlags.leavingToRouteNext = null"
      @discard="reset()"
    />
    <AgentNetworkNotAvailableDialog ref="agentNetworkNotAvailableDialog" />
    <ElDialog
      :center="true"
      top="30vh"
      :show-close="false"
      :visible.sync="showingProfileRecyclingDialogVisibleRef"
      :width="$viewport.lt.md ? 'auto' : '700px'"
      :fullscreen="$viewport.lt.md"
      @close="toUnitPage"
    >
      <ShowingProfileRecycleInquiriesDialog
        :property="propertyRef"
        :inquiries="inquiriesToRecycleRef"
        @close="toUnitPage"
      />
    </ElDialog>
    <div
      v-show="stepsState.active <= stepsState.submit && stepsState.active > 0"
      class="steps-wrapper-transition"
      :style="transition.styles"
    />
    <div
      v-show="stepsState.active <= stepsState.submit && stepsState.active > 0"
      class="steps-wrapper"
    >
      <div
        class="step-wrapper"
        :class="getStepClass(1)"
        :style="flexBasis.styles"
        @click="selectStep(1, STEPS_ORIGINS.MANUAL)"
      >
        <span class="step-icon"><i :class="getStepCheckIconClass(1)" /></span>
        <span class="step-title d-none d-md-block text-capitalize">{{ STEPS.GENERAL }}</span>
      </div>
      <div
        v-if="!hideListingSection"
        class="step-wrapper"
        :class="getStepClass(2)"
        :style="flexBasis.styles"
        @click="selectStep(2, STEPS_ORIGINS.MANUAL)"
      >
        <span class="step-icon"><i :class="getStepCheckIconClass(2)" /></span>
        <span class="step-title d-none d-md-block text-capitalize">{{ STEPS.LISTING }}</span>
      </div>
      <div
        class="step-wrapper"
        :class="getStepClass(3)"
        :style="flexBasis.styles"
        @click="selectStep(3, STEPS_ORIGINS.MANUAL)"
      >
        <span class="step-icon"><i :class="getStepCheckIconClass(3)" /></span>
        <span class="step-title d-none d-md-block text-capitalize">{{ STEPS.PROSPECTS }}</span>
      </div>
      <div
        class="step-wrapper"
        :class="getStepClass(4)"
        :style="flexBasis.styles"
        @click="selectStep(4, STEPS_ORIGINS.MANUAL)"
      >
        <span class="step-icon"><i :class="getStepCheckIconClass(4)" /></span>
        <span class="step-title d-none d-md-block text-capitalize">{{ STEPS.SHOWINGS }}</span>
      </div>
    </div>
    <SdPage>
      <SdLoadingLayer :init="init">
        <template v-slot:loaded>
          <ElForm
            ref="showingProfileForm"
            :model="payload"
            class="row align-items-center justify-content-center"
          >
            <div
              v-show="stepsState.active === 1"
              class="col-12 col-md-6"
            >
              <h2 class="text-capitalize">
                {{ STEPS.GENERAL }}
              </h2>
              <ShowingProfileGeneralInfo
                :showing-profile="listingDataPrefillRef"
                :business="businessRef"
                :property="propertyRef"
                :unit="propertyRef.units[currentUnitIndex]"
                @update-showing-profile-payload="updatePayload"
                @update-property-payload="updatePayload"
              />
              <IntegrationEnabledListingsAlert
                :visible="isUnitSyncedWithIntegration && !isSyndicated"
              />
            </div>
            <div
              v-if="stepsState.active >= 2"
              v-show="stepsState.active === 2"
              class="col-12 col-md-6"
            >
              <div class="d-flex justify-content-between">
                <h2 class="text-capitalize">
                  {{ STEPS.LISTING }}
                </h2>
                <ElButton
                  v-if="isUnitSyncedWithIntegration && !isLegacyIntegrationEnabled"
                  size="medium"
                  class="mb-4"
                  icon="sdicon-download-cloud"
                  :loading="uiFlags.pmsFetchingLoader"
                  @click="listingDataPrefill()"
                >
                  Sync from {{ integrationType }}
                </ElButton>
              </div>
              <div class="row">
                <p class="text-gray-dark col-12">
                  This information will be used to create landing page where prospects will be able to learn more about
                  your property before scheduling a tour.  All of the details, descriptions, and images will help to answer
                  questions and encourage prospects to tour and lease your property.
                </p>
              </div>
              <UnitListings
                mode="ACTIVATE_SHOWING"
                class="d-flex w-100 flex-wrap"
                :payload="payload"
                :unit="propertyRef.units[currentUnitIndex]"
                :index="currentUnitIndex"
                :listing-data-prefill="listingDataPrefillRef"
                :business="businessRef"
                @update-unit="updatePayload"
                @update-rent="updatePayload"
                @update-property-url="url => updatePayload({ property_url: url})"
                @update-availability-date="(date) => updateScreeningPayload(
                  { ...payload.screening_criteria, availability_date: date },
                  'screening_criteria'
                )"
                @update-move-in-cost="(moveInCost) => updateScreeningPayload(
                  { ...payload.screening_criteria, move_in_cost: moveInCost },
                  'screening_criteria'
                )"
              />
              <UnitTags
                mode="ACTIVATE_SHOWING"
                class="d-flex w-100 flex-wrap pt-4"
                :selected-tags="payload.tags"
                :selectable-tags="selectableTagsRef"
                :unit.sync="unit"
                :property-unit-index="currentUnitIndex"
                @update-unit="updatePayload"
              />
              <UnitGenerateAd
                v-if="AllowedGenerate"
                :key="UnitGenerateAdComponentKey"
                prop-class="d-flex flex-wrap pt-4 justify-content-end pr-4"
                :is-disabled="!allowGeneratingAd"
                :additional-information="additionalInformation"
                :property="propertyRef"
                :unit.sync="unit"
                :payload="payload"
                @update-payload="value => updateTitleAndDescription(value)"
                @update-additional-information="value => updateAdditionalInformation(value)"
              />
              <UnitTitleAndDescription
                class="d-flex w-100 flex-wrap pt-4"
                :unit="unit"
                :property-unit-index="currentUnitIndex"
                :listing-data-prefill="listingDataPrefillRef"
                mode="ACTIVATE_SHOWING"
                @update-unit="updatePayload"
              />
              <UnitMediaGallery
                ref="unit-media-gallery-edit"
                class="d-flex w-100 flex-wrap pt-4"
                :unit="unit"
                :property-unit-index="currentUnitIndex"
                :listing-data-prefill="listingDataPrefillRef"
                mode="ACTIVATE_SHOWING"
                :show-hero-image="false"
                @update-unit="updatePayload"
              />
            </div>
            <div
              v-if="stepsState.active >= 3"
              v-show="stepsState.active === 3"
              class="col-12 col-md-6"
            >
              <h2 class="text-capitalize">
                {{ STEPS.PROSPECTS }}
              </h2>
              <ShowingProfileNotesToProspect
                :general-notes="payload.general_notes"
                :showing-profile="listingDataPrefillRef"
                :business="businessRef"
                @change="(sc) => updateScreeningPayload(sc, 'general_notes')"
                @update-showing-profile-payload="updatePayload"
              />
              <ElDivider />
              <div class="row align-items-center mb-4">
                <div class="col">
                  <h2 class="mb-0">
                    Pre-screening
                  </h2>
                </div>
                <div class="col-auto">
                  <SdFloatLabel>
                    <ElSelect
                      v-model="payload.screening_template_id"
                      @change="updateScreeningTemplateDisplay($event)"
                    >
                      <ElOption
                        v-for="item in screeningTemplatesRef"
                        :key="item.id"
                        :label="item.title"
                        :value="item.id"
                      />
                    </ElSelect>
                  </SdFloatLabel>
                </div>
              </div>
              <ShowingProfilePreScreening
                :key="payload.screening_template_id"
                :screening-template="selectedScreeningTemplateRef"
                :is-pre-screening-enabled="payload.is_pre_screening_enabled"
              >
                <template v-slot:property-screening>
                  <ShowingProfilePropertyScreening
                    :screening-criteria="cleanAndMergedPayload"
                    :unit="unit"
                    @change="(sc)=>updateScreeningPayload(sc,'property_screening_criteria')"
                  />
                </template>
                <template v-slot:unit-screening>
                  <ShowingProfileUnitScreening
                    :screening-criteria="payload.screening_criteria"
                    :listing-data="listingDataPrefillRef"
                    @change="(additional_criteria) => updateScreeningPayload(
                      { ...payload.screening_criteria, ...additional_criteria },
                      'screening_criteria'
                    )"
                    @custom-question-update="custom_screening => updateScreeningPayload(
                      custom_screening,
                      'custom_screening'
                    )"
                  />
                </template>
                <template v-slot:additional-unit-screening>
                  <ShowingProfileAdditionalUnitScreening
                    :additional-unit-screening="payload.screening_criteria.additional_criteria"
                    @change="(additional_criteria) => updateScreeningPayload(
                      { ...payload.screening_criteria, additional_criteria },
                      'screening_criteria'
                    )"
                  />
                </template>
              </ShowingProfilePreScreening>
            </div>
            <div
              v-if="stepsState.active >= 4"
              v-show="stepsState.active === 4"
              class="col-12 col-md-6"
            >
              <h2 class="text-capitalize">
                {{ STEPS.SHOWINGS }}
              </h2>
              <ShowingProfileEnable
                class="mt-3"
                :property="propertyRef"
                :showing-profile="payload"
                :is-self-showing="true"
                :with-tour-limit="false"
                :form-ref="showingProfileFormRef"
                @activate-showing="showingActivated"
                @update-showing-profile-payload="updatePayload"
              >
                <div
                  slot="access-instructions"
                  class="my-4"
                >
                  <div class="font-21 mb-1 text-gray-darker">
                    Access instructions
                  </div>
                  <div class="mb-3 font-16 text-gray-dark">
                    Note that Showdigs does not support MLS lockboxes
                  </div>
                  <ShowingProfileAccessInstructions
                    v-if="businessRef"
                    :business="businessRef"
                    :property="propertyRef"
                    :showing-profile="payload"
                    :unit="unit"
                    @update-property-payload="updatePayload"
                    @update-showing-profile-payload="updatePayload"
                  />
                </div>
              </ShowingProfileEnable>
            </div>
            <div class="col-12 p-0" />
            <div class="col-12 col-md-6 text-right">
              <ElButton
                v-show="stepsState.active < 4 && stepsState.active > 0"
                type="primary"
                size="medium"
                @click="selectStep(stepsState.active + 1)"
              >
                Next
              </ElButton>
            </div>
          </ElForm>
        </template>
      </SdLoadingLayer>
    </SdPage>
  </div>
</template>

<script>
import {
 computed, reactive, ref, watch,
} from '@vue/composition-api';
import ShowingProfilePreScreening from '@/components/showing-profile/ShowingProfilePreScreening';
import ShowingProfileGeneralInfo from '@/components/showing-profile/ShowingProfileGeneralInfo';
import ShowingProfileAccessInstructions from '@/components/showing-profile/ShowingProfileAccessInstructions';
import ShowingProfilePropertyScreening, { propertyScreeningEmptyState } from '@/components/showing-profile/ShowingProfilePropertyScreening';
import ShowingProfileUnitScreening from '@/components/showing-profile/ShowingProfileUnitScreening';
import ShowingProfileNotesToProspect from '@/components/showing-profile/ShowingProfileNotesToProspect';
import ShowingProfileAdditionalUnitScreening from '@/components/showing-profile/ShowingProfileAdditionalUnitScreening';
import ShowingProfileEnable from '@/components/showing-profile/ShowingProfileEnable';
import ShowingProfileRecycleInquiriesDialog from '@/components/showing-profile/ShowingProfileRecycleInquiriesDialog';
import SdDiscardChangesDialog from '@/components/common/form/SdDiscardChangesdDialog';
import AgentNetworkNotAvailableDialog from '@/components/billing/AgentNetworkNotAvailableDialog';
import UnitMediaGallery from '@/components/unit/UnitMediaGallery';
import UnitTags from '@/components/unit/UnitTags';
import UnitListings from '@/components/unit/UnitListings';
import UnitTitleAndDescription from '@/components/unit/UnitTitleAndDescription';
import Plan from '@/constants/Plan';
import { defaultUnitScreeningPayload } from '@/constants/Unit';
import { STEPS, STEPS_ORIGINS } from '@/constants/Activiation';
import { AccessHardware } from '@/constants/AccessHardware';
import get from 'lodash.get';
import UnitGenerateAd from '@/components/unit/UnitGenerateAd';
import { cleanAndMergeWithDefaultPayload } from '@/utils/payloadUtil';
import IntegrationEnabledListingsAlert from '@/components/common/IntegrationEnabledListingsAlert';
import SdFloatLabel from '@/components/common/form/SdFloatLabel.vue';
import {
  availabilityDateFormat,
  resolveAllowGeneratingAd,
  resolveApplicationUrl,
  resolveHideListingSection,
} from '@/utils/ShowingProfileUtil';
import { capitalizeFirstLatter } from '@/utils/StringUtil';
import { getBusinessSourceType, isDataPrefill } from '@/utils/BusinessSourceUtil';

export default {
  name: 'SelfShowingProfileAdd',
  components: {
    SdFloatLabel,
    UnitGenerateAd,
    IntegrationEnabledListingsAlert,
    ShowingProfileAdditionalUnitScreening,
    ShowingProfileNotesToProspect,
    ShowingProfilePreScreening,
    SdDiscardChangesDialog,
    ShowingProfileRecycleInquiriesDialog,
    ShowingProfileGeneralInfo,
    ShowingProfileAccessInstructions,
    ShowingProfilePropertyScreening,
    ShowingProfileUnitScreening,
    ShowingProfileEnable,
    UnitListings,
    UnitTags,
    UnitMediaGallery,
    UnitTitleAndDescription,
    AgentNetworkNotAvailableDialog,
  },
  beforeRouteLeave(to, from, next) {
    // show discard changes model when leaving between access instructions and activation steps
    if (this.stepsState.active > 1 && this.stepsState.active < 4) {
      this.uiFlags.leavingToRouteNext = next;
    } else {
      next();
    }
  },
  setup(props, context) {
    const store = context.root.$store;
    const propertyRef = ref(null);
    const unit = ref(null);
    const transition = reactive({ styles: '' });
    const stepsState = reactive({ ...defaultStepsState });
    const payload = reactive({ ...defaultPayload });
    const showingProfile = ref(null);
    const showingProfileRecyclingDialogVisibleRef = ref(false);
    const inquiriesToRecycleRef = ref([]);
    const uiFlags = reactive({
      leavingToRouteNext: null,
      isUploadingImages: false,
      pmsFetchingLoader: false,
    });
    const UnitGenerateAdComponentKey = ref(0);
    const businessDefaultScreeningRef = ref(null);
    const screeningCriteriaRef = ref(null);
    const businessRef = ref({});
    const screeningTemplatesRef = ref({});
    const selectedScreeningTemplateRef = ref({});
    const selectableTagsRef = ref(null);
    const additionalInformation = ref('');
    const listingDataPrefillRef = ref(null);
    const selfShowing = ref(true);
    const unitId = Number(context.root.$route.params.unit_id);
    const currentUnitIndex = ref(null);
    const isIntegrationEnabled = context.root.$store.getters['BusinessSource/isIntegrationEnabled'];
    const isLegacyIntegrationEnabled = context.root.$store.getters['BusinessSource/isLegacyIntegrationEnabled'];
    const isSyndicated = context.root.$store.getters['Auth/businessSyndicated'];
    const isUnitSyncedWithIntegration = ref(false);
    const showingProfileFormRef = ref(context.refs.showingProfileForm);
    const AllowedGenerate = ref(false);
    const allowGeneratingAd = ref(false);
    const cleanAndMergedPayload = ref(null);
    const hideListingSection = resolveHideListingSection(context);
    const flexBasis = reactive({ styles: !hideListingSection ? 'flex-basis:25%' : 'flex-basis:33%' });
    const integrationType = capitalizeFirstLatter(getBusinessSourceType());
    watch(() => stepsState.active, changeTransition, { immediate: true });

    return {
      init,
      stepsState,
      payload,
      propertyRef,
      updatePayload,
      updateScreeningPayload,
      selectStep,
      reset,
      getStepClass,
      toUnitPage,
      transition,
      showingProfile,
      showingProfileFormRef,
      inquiriesToRecycleRef,
      showingProfileRecyclingDialogVisibleRef,
      uiFlags,
      businessDefaultScreeningRef,
      screeningCriteriaRef,
      businessRef,
      listingDataPrefillRef,
      currentUnitIndex,
      showingActivated,
      selfShowing,
      unit,
      selectableTagsRef,
      STEPS,
      STEPS_ORIGINS,
      isUnitSyncedWithIntegration,
      AllowedGenerate,
      additionalInformation,
      allowGeneratingAd,
      updateTitleAndDescription,
      UnitGenerateAdComponentKey,
      updateAdditionalInformation,
      cleanAndMergeWithDefaultPayload,
      isSyndicated,
      screeningTemplatesRef,
      selectedScreeningTemplateRef,
      updateScreeningTemplateDisplay,
      cleanAndMergedPayload,
      getStepCheckIconClass,
      hideListingSection,
      flexBasis,
      integrationType,
      isIntegrationEnabled,
      listingDataPrefill,
      isLegacyIntegrationEnabled,
    };

    function getCleanAndMergeWithDefaultPayload() {
      cleanAndMergedPayload.value = cleanAndMergeWithDefaultPayload(payload.property_screening_criteria, businessDefaultScreeningRef.value);
    }

    function updateScreeningTemplateDisplay(screeningTemplateId) {
      selectedScreeningTemplateRef.value = screeningTemplatesRef.value.find((element, index) => element.id === screeningTemplateId);
    }

    function updateAdditionalInformation(value) {
      additionalInformation.value = value;
    }

    function updateTitleAndDescription(value) {
      // eslint-disable-next-line no-multi-assign
      unit.value.description = payload.description = value.description;
      // eslint-disable-next-line no-multi-assign
      unit.value.short_description = payload.short_description = value.short_description;
    }

    function resolveDisableGenerate() {
      const isIntegrationEnabled = store.getters['BusinessSource/isIntegrationEnabled'];
      return isIntegrationEnabled && !isSyndicated && (Boolean(unit.value.listing_identifier) || Boolean(unit.value.origin_id));
    }

    function getInitialSelectedAccessHardware(business, showingProfile) {
      const isCodeboxEnabled = business.device_connected;
      const isContractorLockboxEnabled = Boolean(business.self_showing_contractor_lockbox_enabled_at);
      let type = AccessHardware.CONTRACTOR_LOCKBOX;

      if (showingProfile?.access_hardware) {
        type = showingProfile.access_hardware;
      } else if (isContractorLockboxEnabled) {
        type = AccessHardware.CONTRACTOR_LOCKBOX;
      } else if (isCodeboxEnabled) {
        type = AccessHardware.CODEBOX;
      }

      return type;
    }

    function getStepClass(stepNumber) {
      let classes = '';
      if (stepNumber === stepsState.active) {
        classes += ' is-active';
      }
      if (stepNumber < stepsState.active) {
        classes += ' is-processed';
      }
      return classes;
    }

    function getStepCheckIconClass(stepNumber) {
      let classes = 'd-none';
      if (stepNumber < stepsState.active) {
        classes = 'sdicon-check text-primary';
      }

      if (stepNumber === stepsState.active) {
        classes = 'text-primary';
      }

      return classes;
    }

    function changeTransition() {
      let widthPercent = (100 / stepsState.submit) * stepsState.active;
      if (hideListingSection) {
        const step = stepsState.active > 1 ? stepsState.active - 1 : stepsState.active;
        widthPercent = (100 / (stepsState.submit - 1)) * step;
      }
      transition.styles = `width:${widthPercent}%;border-radius: 0 50px 50px 0;`;
      showingProfileFormRef.value = context.refs.showingProfileForm;
    }

    async function init() {
      screeningCriteriaRef.value = store.getters['ScreeningCriteria/get'] || await store.dispatch('ScreeningCriteria/load');

      businessRef.value = await store.dispatch('Business/me');
      selectableTagsRef.value = (await store.dispatch('Business/getListingTags'));
      propertyRef.value = await store.dispatch(
        'Property/find',
        context.root.$route.params.id,
      );
      businessDefaultScreeningRef.value = businessRef.value.default_screening_criteria || propertyRef.value.screening_criteria || {};

      currentUnitIndex.value = propertyRef.value.units.findIndex((unit) => unit.id === unitId);
      payload.general_notes = businessRef.value.default_general_notes ?? payload.general_notes;
      unit.value = propertyRef.value.units[currentUnitIndex.value];
      isUnitSyncedWithIntegration.value = isIntegrationEnabled && (Boolean(unit.value.listing_identifier) || Boolean(unit.value.origin_id));
      await payloadDataPrefill();
      AllowedGenerate.value = !resolveDisableGenerate();
      const currentPlan = store.getters['Auth/plan'];
      if (currentPlan.name === Plan.BASIC && propertyRef.value.market_id === null) {
        context.refs.agentNetworkNotAvailableDialog.open();
      }
      screeningTemplatesRef.value = businessRef.value.screening_templates.filter((value, index) => value.enabled === true);
      payload.screening_template_id = screeningTemplatesRef.value[0].id;
      selectedScreeningTemplateRef.value = screeningTemplatesRef.value[0];
      payload.has_identity_verification = Boolean(businessRef.value.identity_verification);
      businessDefaultScreeningRef.value = businessDefaultScreeningRef.value
        || selectedScreeningTemplateRef.value.property_screening
        || propertyRef.value.screening_criteria || {};
      updateScreeningPayload(businessDefaultScreeningRef.value, 'property_screening_criteria');
      getCleanAndMergeWithDefaultPayload();
    }

    async function payloadDataPrefill() {
      payload.bedrooms = unit.value.bedrooms;
      payload.bathrooms = unit.value.bathrooms;
      payload.size = unit.value.size;
      payload.tags = unit.value.tags;
      payload.video = unit.value.video;
      payload.description = unit.value.description;
      payload.short_description = unit.value.short_description;
      payload.images = unit.value.images;
      await listingDataPrefill(isDataPrefill());
      payload.unit_id = context.root.$route.params.unit_id;
      payload.common_agent_notes = propertyRef.value.common_agent_notes;
      payload.owners_emails = propertyRef.value.owners_emails;
      payload.access_hardware = getInitialSelectedAccessHardware(businessRef.value, showingProfile.value);
    }

    async function listingDataPrefill(fullPrefill = true) {
      if (unit.value && (unit.value.listing_identifier || unit.value.origin_id)) {
        uiFlags.pmsFetchingLoader = true;
        try {
          const prefill = await store.dispatch('Listing/show', unit.value.id);
          if (prefill) {
            if (!fullPrefill) {
              listingDataPrefillRef.value = {
                application_form_url: resolveApplicationUrl(prefill, businessRef),
              };
              uiFlags.pmsFetchingLoader = false;
              return;
            }
            const prefillAmenities = prefill.amenities || null;
            listingDataPrefillRef.value = {
              bedrooms: prefill.bedrooms,
              bathrooms: prefill.bathrooms,
              size: prefill.size,
              rent: prefill.rent,
              availability_date: prefill.availability_date ? availabilityDateFormat(prefill.availability_date) : null,
              short_description: prefill.short_description,
              description: prefill.description,
              video: prefill.video,
              images: prefill.images,
              application_form_url: resolveApplicationUrl(prefill, businessRef),
              move_in_cost: prefill.move_in_cost,
            };
            // array to set to array - make sure after merge we clen duplicates
            payload.tags = prefillAmenities ?? payload.tags;
            payload.video = prefill.video ?? payload.video;
            payload.description = prefill.description ?? payload.description;
            payload.short_description = prefill.short_description ?? payload.short_description;
            payload.rent = prefill.rent ?? payload.rent;
            payload.bedrooms = prefill.bedrooms ?? payload.bedrooms;
            payload.bathrooms = prefill.bathrooms ?? payload.bathrooms;
            payload.images = prefill.images ?? payload.images;
            payload.size = prefill.size ?? payload.size;
            const prefillScreening = get(prefill, 'screening_criteria') ?? {};
            businessDefaultScreeningRef.value = {
              ...businessDefaultScreeningRef.value,
              ...prefillScreening,
            };
          }
        } catch (e) { }
        uiFlags.pmsFetchingLoader = false;
      }
    }

    function reset() {
      if (uiFlags.leavingToRouteNext) {
        uiFlags.leavingToRouteNext();
      }
      uiFlags.leavingToRouteNext = null;
      selectStep(0);
    }

    async function uploadRequiredImages() {
      uiFlags.isUploadingImages = true;
      try {
        const images = await context.refs?.['unit-media-gallery-edit']?.uploadImages?.();
        updatePayload({ images });
      } finally {
        uiFlags.isUploadingImages = false;
      }
    }

    function selectStep(step, stepOrigin = STEPS_ORIGINS.NEXT) {
      async function setStep() {
        if (uiFlags.isUploadingImages) {
          return; // Prevent navigating or re-uploading photos until the last upload requests finishes
        }

        if (
            stepsState.active === 1
          && hideListingSection
            && stepOrigin === STEPS_ORIGINS.NEXT
        ) {
          step = 3;
        }

        if (stepsState.active === 2) {
          await uploadRequiredImages();
        }

        stepsState.active = step;
        if (stepsState.last < stepsState.active) {
          stepsState.last = stepsState.active;
        }
      }
      try {
        // dont need to validate after submit or when going back
        if (step > stepsState.submit || step <= stepsState.active) {
          setStep();
          return;
        }
        context.refs.showingProfileForm.validate((valid) => {
          if (valid) {
            setStep();
          }
        });
      } catch (error) {
        if (!(error instanceof Error)) {
          // Validator failed
        }
      }
    }

    function updatePayload(componentFields) {
      const fields = componentFields.value ? componentFields.value : componentFields;
      Object.keys(fields).forEach((key) => {
        if (key in payload) {
          payload[key] = fields[key];
        }
      });
      UnitGenerateAdComponentKey.value += 1;
      allowGeneratingAd.value = resolveAllowGeneratingAd(payload);
    }

    function updateScreeningPayload(screeningCriteria, key) {
      updatePayload({ [key]: screeningCriteria });
    }

    async function showingActivated(data) {
      showingProfile.value = data.showingProfile;

      inquiriesToRecycleRef.value = await store.dispatch('Unit/checkFailedInquiries', context.root.$route.params.unit_id);
      if (inquiriesToRecycleRef.value.length) {
        showingProfileRecyclingDialogVisibleRef.value = true;
      } else {
        toUnitPage();
      }
    }
    function toUnitPage() {
      context.root.$router.push({
        name: 'app.units.show',
        params: context.root.$route.params.unit_id,
      });
    }
  },
};

const defaultStepsState = {
  active: 1, // Current step
  last: 1, // Last active step
  submit: 4, // Submit step
};

const defaultPayload = {
  property_url: null,
  application_form_url: '',
  listing_manager_id: '',
  owners_emails: null,
  send_owner_reports: false,
  device_id: null,
  access_hardware: null,
  pms_code: null,
  lock_box_code: '',
  comments: '',
  is_vacant: true,
  is_open_house: false,
  is_self_showing: true,
  is_publish_listing: false,
  has_identity_verification: false,
  paused_until: null,
  pause_reason: null,
  terms: false,
  tenants_notified: false,
  unit_id: null,
  max_budgeted_tours: null,
  property_screening_criteria: propertyScreeningEmptyState,
  screening_criteria: { ...defaultUnitScreeningPayload },
  custom_screening: [],
  is_pre_screening_enabled: true,
  general_notes: '',
  with_move_in_inspection: false,
  with_showing_phone_line: false,
  is_agent_backup_enabled: false,
  bedrooms: null,
  bathrooms: null,
  size: null,
  rent: null,
  images: [],
  tags: [],
  video: '',
  short_description: '',
  description: '',
  screening_template_id: null,
  sync_application_url: null,
};
</script>

<style lang="scss">
.steps-wrapper {
  width: 100%;
  left: 0;
  position: absolute;
  background: gray-color(lighter);
  display: flex;
}

.step-wrapper {
  height: 48px;
  cursor: pointer;
  z-index: 1;
  justify-content: center;
  display: flex;
  align-items: center;

  &.is-active,
  &.is-processed {
    .step-icon {
      background: $white;
      border-color: $white;
    }

    .step-title {
      color: $white;
    }
  }

  &.is-processed {
    .step-icon {
      border-color: $white;
      color: theme-color(primary);
    }

    .step-icon:before {
      content: "\e6da";
      font-family: 'element-icons' !important;
      color: theme-color(primary);
      padding: 0 5px;
    }
  }

  &.is-active {
    .step-icon {
      background: theme-color(primary);
      border-color: $white
    }
  }
}

.steps-wrapper-transition {
  transition: all 0.5s ease-in-out;
  z-index: 1;
  height: 48px;
  left: 0;
  position: absolute;
  background: theme-color(primary);
}

.step-icon {
  border-radius: 50%;
  width: 24px;
  color: $white;
  height: 24px;
  text-align: center;
  border: 3px solid #B4C5D0;
}

.step-title {
  margin-left: 16px;
  color: gray-color();
}

.page {
  padding-top: 5rem;
}

.page-showing-setup {
  .gapped-rows {
    row-gap: 1.5rem;
  }

  .loading-layer {
    margin-top: 60px;
  }
}

.sdicon-check {
  position: relative;
  top: -2px;
  right: 1px;
}
</style>
